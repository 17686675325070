<template>
  <div class="newsBox">
    <el-row :gutter="20">
      <el-col :xs="12" :sm="8" :md="8" :lg="6" :xl="6" v-for="(val, index) in newList" :key="index+'news'">
        <el-card class="cardItem" :body-style="{ padding: '8px' }">
          <div @click="cardChange(val)">
            <div class="card_img">
              <img class="image" :src="val.preview_url || val.preview_photo_url" alt="">
            </div>
            <div class="card_foot">
              <p class="title">{{ val.title }}</p>
              <p class="subtext">摘要:{{ val.preview_content }}</p>
              <div class="time">{{ val.created_at }}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-dialog :modal-append-to-body="false" width="800px" top="10vh" :visible.sync="dialogVisible">
      <div slot="title">
        行业动态
      </div>
      <div class="diag_title">
        {{ diagForm.title }}
      </div>
      <div v-html="diagForm.content"></div>
      <div class="diag_time">{{ diagForm.created_at }}</div>
    </el-dialog>
  </div>
</template>

<script>
import { requestServices } from "../../api/api";
import { mapGetters } from "vuex";

export default {
  name: "news",
  computed: {
    ...mapGetters(["isMobile"]),
  },
  data() {
    return {
      newList: [],
      dialogVisible: false,
      diagForm: ''
    }
  },
  mounted() {
    requestServices.newsList().then(res => {
      let _data = res.result.news
      this.newList = _data.filter(item => item.status !== '0')
    })
  },
  methods: {
    cardChange(val) {
      if(this.isMobile){
        this.$router.push({
          path:'/news',
          query: val
        })
      }else{
        this.diagForm = val
        this.dialogVisible = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.newsBox {
  width: 12rem;
  padding-bottom: 0.7rem;
  margin: 0 auto;

  .cardItem {
    //width: 300px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover .image {
      transform: scale(1.1);
    }
  }

  .card_img {
    width: 100%;
    max-height: 214px;
    overflow: hidden;
    display: flex;
    align-items: center;

    .image {
      width: 100%;
      //height: 100%;
      transform: scale(1);
      transition: all 1s;
    }
  }

  .card_foot {
    height: 97px;
    padding: 10px 15px;

    .title {
      font-size: 14px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 4px;
    }

    .subtext {
      font-size: 12px;
      color: #414141;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 4px;
    }

    .time {
      font-size: 13px;
      color: #717071;
      bottom: 8px;
    }
  }
}

/deep/ .el-dialog__header {
  padding: 15px 20px 15px;
  height: 20px;
  background: #18204a;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  letter-spacing: 2px;
}

.diag_title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.diag_time {
  text-align: right;
  font-size: 13px;
  color: #8c939d;
  line-height: 28px;
}

.news_dialog_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3000;

  .news_dialog {
    width: 800px;
    position: absolute;
    top: 20vh;
    left: 50%;
    margin-left: -400px;
    background: rgba(255, 255, 255, 1);

    .news_dialog_header {
      padding: 15px 20px;
      height: 20px;
      background: #18204a;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      letter-spacing: 2px;

      .dialog_header_title {

      }

      .close_btn {
        position: absolute;
        right: 10px;
        cursor: pointer;
      }
    }

    .news_dialog_body {
      padding: 30px 20px;
      color: #606266;
      font-size: 14px;
      word-break: break-all;
    }
  }
}

.el-row{
  display: flex;
  flex-wrap: wrap;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .newsBox {
    width: 94%;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .newsBox {
    //padding: 0.7rem 0.5rem;
    //padding-bottom: 0.7rem;
    width: calc(100% - 0.4rem);

    .swiper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .cardItem {
      //width: 200px;
      //margin-right: 20px;
      margin-top: 10px;
    }

    .card_img {
      width: 100%;
      max-height: 160px;

      .image {
        width: 100%;
        //height: 100%;
        //transform: scale(1);
        //transition: all 1s;
      }
    }

    .card_foot {
      height: 97px;
      padding: 15px 0 0 0;
      position: relative;

      .title {
        font-size: 14px;
        font-weight: 500;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 4px;
      }

      .subtext {
        font-size: 12px;
        color: #414141;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        margin-bottom: 4px;
      }

      .time {
        font-size: 13px;
        color: #717071;
        bottom: -2px;
      }
    }
  }

  .news_dialog_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 3000;

    .news_dialog {
      width: 100%;
      position: fixed;
      bottom: 0;
      background: rgba(255, 255, 255, 1);

      .news_dialog_header {
        padding: 15px 20px;
        height: 20px;
        background: #18204a;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 2px;

        .dialog_header_title {

        }

        .close_btn {
          position: absolute;
          right: 10px;
          cursor: pointer;
        }
      }

      .news_dialog_body {
        padding: 30px 20px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
      }
    }
  }
}
</style>
