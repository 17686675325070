<template>
  <div>
    <!--    <swiper v-if="false" :options="swiperOption" ref="mySwiper" class="h100">-->
    <!--      <swiper-slide :style="{'height':screenHeight+'px'}">-->
    <!--        <home-one v-show="contentVisible" class="home_boxf"></home-one>-->
    <!--      </swiper-slide>-->

    <!--      <swiper-slide style="height: 2000px;background:rgba(255,255,255,1);">-->
    <!--        <div>-->
    <!--          <div class="multiple">-->
    <!--            <multiple></multiple>-->
    <!--          </div>-->
    <!--          <div v-if="!isMobile" class="news">-->
    <!--            <my-news></my-news>-->
    <!--          </div>-->
    <!--          <my-footer></my-footer>-->
    <!--        </div>-->
    <!--      </swiper-slide>-->
    <!--      &lt;!&ndash;      <swiper-slide style="background:rgba(255,255,255,1);">&ndash;&gt;-->
    <!--      &lt;!&ndash;        <div class="multiple">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <multiple></multiple>&ndash;&gt;-->
    <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </swiper-slide>&ndash;&gt;-->
    <!--      &lt;!&ndash;      <swiper-slide style="background:rgba(255,255,255,1);">&ndash;&gt;-->
    <!--      &lt;!&ndash;          <div class="news">&ndash;&gt;-->
    <!--      &lt;!&ndash;            <my-news></my-news>&ndash;&gt;-->
    <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--      &lt;!&ndash;        <my-footer></my-footer>&ndash;&gt;-->
    <!--      &lt;!&ndash;      </swiper-slide>&ndash;&gt;-->
    <!--    </swiper>-->

    <div :style="{'height':screenHeight+'px'}">
      <home-one v-show="contentVisible" class="home_boxf"></home-one>
    </div>
    <div id="home_body" style="background:rgba(255,255,255,1);">
      <div class="multiple">
        <multiple></multiple>
      </div>
      <div class="news">
        <u-animate-container>
          <u-animate name="zoomIn" delay="0s" duration="0.5s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
            <div class="titleBox">
              <p class="title1">新闻动态</p>
              <span class="title1-line"></span>
              <p class="title2">News</p>
            </div>
          </u-animate>
        </u-animate-container>
        <my-news></my-news>
      </div>
      <my-footer></my-footer>
    </div>
  </div>
</template>

<script>
import * as swiperAni from '@/assets/js/swiper.animate.min.js'
// import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import homeOne from './home-one'
import multiple from './multiple'
import news from "@/view/home/news";
import footer from "@/components/footer"
import { mapGetters } from "vuex";

export default {
  name: "index",
  components: {
    homeOne,
    // swiper, swiperSlide,
    'multiple': multiple,
    'my-news': news,
    'my-footer': footer
  },
  computed: {
    ...mapGetters(["isMobile",'clientWidth']),
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },
  data() {
    let self = this;
    return {
      timeNow: '',
      contentVisible: false,
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        setWrapperSize: false, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        slidesPerView: 'auto', //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        autoHeight: true,
        // effect: 'flip',
        // 变手势
        grabCursor: false,//指针变爪爪
        autoplay: false,
        initialSlide: 0,

        // 滑动方向，水平horizontal，垂直vertical
        direction: 'vertical',
        // 如果需要滚动条
        scrollbar: {
          el: '.swiper-scrollbar',
        },
        // init:false,
        observer: true,
        on: {
          init: function () {
            // console.log('inithome',this)
            // swiperAni.swiperAnimateCache(this); //隐藏动画元素
            swiperAni.swiperAnimate(this); //初始化完成开始动画
          },
          slideChange: function () {
            swiperAni.swiperAnimate(this)
            console.log(self.$refs.mySwiper.swiper.realIndex)
            self.$emit('slideChange', self.$refs.mySwiper.swiper.realIndex)
          },
          slideChangeTransitionEnd: function () {
            //切换结束时，告诉我现在是第几个slide
            // swiperAni.swiperAnimateCache(this); //隐藏动画元素
          }
        }
      },
      slideHeight: '',
      screenHeight: document.body.clientHeight,
      screenWidth: '',
    }
  },
  watch: {
    screenWidth(val) {
      this.slideHeight = this.screenHeight + 'px';
      if (val < 760 && this.isMobile) {
        this.slideHeight = '900px'
      }
    },
    $route(to) {
      if (to.name === 'home') {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'continue' }, '*');
      } else {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'stop' }, '*');
        let bodystyle = document.body.style
        if (to.name !== 'home' && bodystyle) {
          document.body.style.overflow = "";
        }
      }
    },
  },
  mounted() {
    let self = this;
    if (this.isMobile) {
      setTimeout(() => {
        self.contentVisible = true;
      }, 1500)
    } else {
      window.addEventListener('message', function (res) {
        if (typeof (res.data) === "string") {
          let _state = JSON.parse(res.data).state
          if (_state === 'finished') {
            self.contentVisible = true;
          }
        }
      });

      setTimeout(() => {
        self.navVisible = true;
      }, 3000)
    }
    this.screenWidth = document.body.clientWidth;
    this.screenHeight = document.body.clientHeight;

    window.onresize = () => {
      var bl = document.documentElement.clientWidth / 1440
      var mbl = document.documentElement.clientWidth / 414
      return (() => {
        self.screenWidth = document.body.clientWidth;
        self.screenHeight = document.body.clientHeight;

        if (self.screenWidth > 767) {
          if (bl >= 0.84) {
            document.documentElement.style.fontSize = parseInt(100 * bl) + 'px'
          } else {
            document.documentElement.style.fontSize = 100 + 'px'
          }
        } else if (self.screenWidth > 319 && self.screenWidth <= 767 && self.isMobile) {
          document.documentElement.style.fontSize = parseInt(100 * mbl) + 'px'
        }
        // console.log('fontSize',document.documentElement.style.fontSize)
      })();
    };
  },
  methods: {
    navBgChange(val) {
      this.navBgStyle = val;
      if (val) {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'stop' }, '*');
      } else {
        this.$refs.contentIframe.contentWindow.postMessage({ type: 'continue' }, '*');
      }
    }
  }
}
</script>

<style lang="less" scoped>
.multiple {
  padding-top: 1rem
}

.news {
  background: #F8F8FF;

  .titleBox {
    text-align: center;
    padding: .5rem 0 .43rem 0;
    font-size: 0;
    color: #333333;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }

    .title1 {
      text-align: center;
      font-size: .32rem;
      font-weight: 500;
    }

    .title1-line {
      display: block;
      margin: 0 auto;
      width: 70px;
      height: 3px;
      background-color: #5b5af3;
      border-radius: 20px;
      transform: rotateY(90deg);
      transition: all 1s;
    }

    &:hover .title1-line {
      transform: rotateY(0deg);
    }

    .title2 {
      font-size: 21px;
      font-family: Impact, Charcoal, sans-serif;
      font-weight: normal;
      color: #7776ff33;
      line-height: 31px;
      letter-spacing: 3px;
      font-style: italic;
      margin-top: 0.06rem;
      display: inline-block;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .multiple {
    padding: 1rem 0.2rem 0;
  }

  .news {
    //padding: 1rem 0.2rem 0;

    .titleBox {
      padding: 0.3rem 0 0.1rem;

      .title1 {
        font-size: 20px;
      }

      .title2 {
        margin-top: 0;
        font-size: 14px;
        letter-spacing: 2px;
      }
    }
  }
}

</style>
