<template>
  <tabs v-model="activeName" :data="tabsData">
    <div v-for="(tab,idx) in tabsData" :key="idx" :slot="tab.name">
      <introduction :productIntroduction="tab.productIntroduction" :type="tab.type" showMore></introduction>
    </div>
  </tabs>
</template>

<script>
import tabs from "@/components/tab";
import introduction from "@/components/introduction";
import { ProductIntroduction } from "@/assets/js/fixed_data"
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isMobile",'clientWidth']),
    dataHeight() {
      if (this.isMobile) {
        return '9rem'
      } else {
        return '6rem'
      }
    }
  },
  components: {
    tabs, introduction
  },
  data() {
    return {
      autoplay: true,
      activeName: 'intelligent',
      tabsData: [
        {
          name: "intelligent",
          label: "智能直播",
          productIntroduction: ProductIntroduction.intelligent,
          type: 'left'
        },
        {
          name: "robot",
          label: "智能机器人",
          productIntroduction: ProductIntroduction.robot,
          type: 'right'
        },
        {
          name: "content",
          label: "内容生产",
          productIntroduction: ProductIntroduction.content,
          type: 'left'
        },
        // {
        //   name: "caseOne",
        //   label: "虚拟直播",
        //   productIntroduction: ProductIntroduction.virtual,
        //   type: 'left'
        // },
        // {
        //   name: "caseTwo",
        //   label: "创意营销",
        //   productIntroduction: ProductIntroduction.creative,
        //   type: 'right'
        // }
      ]
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
@media screen and (min-width: 320px) and (max-width: 992px) {
  .multiple {
    padding: 0 1rem;
  }
}
</style>
