<template>
  <div class="tabs">
    <div class="tabs_header">
      <div class="mulFootBox">
        <div class="tab" :class="tab.name === current?'active':''" v-for="(tab,idx) in data" :key="idx" @click="handleClick(tab.name)">
          {{ tab.label }}
        </div>
      </div>
    </div>

    <el-carousel indicator-position="none" arrow="never" ref="mycarousel" class="mul-carousel" trigger="click" :interval="5000" :autoplay="autoplay" :initial-index="0" @change="carouselChange" direction="horizontal">
      <el-carousel-item v-for="(tab,idx) in data" :key="idx" :name="tab.name">
        <slot :name="tab.name"></slot>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    value: String || Number,
    data: {
      type: Array,
      default: function () {
        return []
      }
    },
    autoplay:{
      type: Boolean,
      default: false
    }
  },
  watch:{
    value(val){
      console.log(val)
    }
  },
  data() {
    return {
      current: this.value
    }
  },
  mounted() {
    this.$refs.mycarousel.setActiveItem(0)
  },
  methods: {
    handleClick(name) {
      this.current = name
      this.$refs.mycarousel.setActiveItem(name)
    },
    carouselChange(idx) {
      this.current = this.data[idx].name
    }
  }
}
</script>

<style lang="less" scoped>
.tabs {
  //width: 100%;
  .tabs_header {
    .mulFootBox {
      width: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
      border-radius: 39px;
      background: rgba(119, 118, 255, 0.3);
      color: #7776FF;

      .tab {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-radius: 40px;
        transition: all .5s;
        padding: 10px 20px;

        &:hover {
          cursor: pointer;
        }

        &.active {
          color: #FFFFFF;
          background: #7776FF;
        }
      }
    }
  }
}


.mul-carousel {
  display: flex;
  align-items: center;
  //height: 7.2rem;
}
//
/deep/ .el-carousel__container {
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 1200px) {
  .tabs {
    .mul-carousel {
      height: 5.5rem;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .tabs {
    .mul-carousel {
      height: 6rem;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .tabs {
    .mul-carousel {
      width: 100%;
      height: 6.6rem;
    }
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {

  .tabs {
    .tabs_header {
      .mulFootBox {
        .tab {
          padding: 10px 16px;
          font-size: 14px;
        }
      }
    }
  }

  .mul-carousel {
    width: 100%;
    height: 7.6rem;
  }
}

</style>
